<!--
 * @Author: dongjia
 * @Date: 2021-08-16 17:00:25
 * @LastEditTime: 2021-12-20 14:57:33
 * @LastEditors: aleaner
 * @Description: 新增帖子页面
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\post-admin\add-post.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="group-content" v-loading="formLoading">
    <el-form size="medium" :model="postFrom" :rules="rules" label-position="right" label-width="85px"
      class="small-form" ref="postFrom" @submit.native.prevent>
      <el-form-item label="帖子类型：" prop="type">
        <el-radio v-model="postFrom.type" :label="0">图文</el-radio>
        <el-radio v-model="postFrom.type" :label="1">视频</el-radio>
        <el-radio v-model="postFrom.type" :label="2">推荐帖子</el-radio>
        <el-radio v-model="postFrom.type" :label="3">纯文字</el-radio>
      </el-form-item>
      <el-form-item label="选择话题：" prop="topic_id">
        <el-select v-model="postFrom.topic_id"
          :disabled="(!topicOptions.length || topicOptionsloading) || $route.params.topic_id !== undefined"
          :placeholder="topicOptionsloading?'选项加载中...':!topicOptions.length?'暂无话题可选':'请选择话题'">
          <el-option v-for="item in topicOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属小组：" prop="group_id">
        <el-select v-model="postFrom.group_id"
          :disabled="(!groupOptions.length || groupOptionsLoading) || $route.params.group_id !== undefined"
          :placeholder="groupOptionsLoading?'选项加载中...':!groupOptions.length?'暂无小组可选':'请选择所属小组'">
          <el-option v-for="item in groupOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容：" prop="content" v-if="!formChanging">
        <el-input type="textarea" :rows="6" v-model="postFrom.content" placeholder="请输入内容"></el-input>
        <!-- <tinymce id="tinymce-single-page-editor" v-model="postFrom.content" :height="350">
        </tinymce> -->
      </el-form-item>
      <el-form-item label="图片：" v-if="postFrom.type === 0" prop="images">
        <media-wall v-model="postFrom.images" :width="150" :height="96" :limit="9 - postFrom.images.length"
          addText="上传图片" :UploadType="['image']" :draggable="true"></media-wall>
      </el-form-item>
      <el-form-item label="视频：" v-if="postFrom.type === 1" prop="video">
        <media-wall v-model="postFrom.video" :width="150" :height="96" :limit="1 - postFrom.video.length"
          addText="上传视频" UploadType="video"></media-wall>
      </el-form-item>
      <el-form-item label="帖子：" v-if="postFrom.type === 2" prop="recommendPost">
        <el-button type="primary" @click="openRecommandPostSelector = true">选择帖子</el-button>
        <el-button type="danger" v-if="postFrom.recommendPost" @click="postFrom.recommendPost = ''">删除
        </el-button>
        <div class="post-box" v-if="postFrom.recommendPost">
          <div class="post-content">
            <div v-html="postFrom.recommendPost.content"></div>
            <!-- popover图片 -->
            <div v-if="postFrom.recommendPost.images.length" :style="{
                  marginTop: postFrom.recommendPost.content?'13px':''
                }" class="popover-imgs">
              <el-image v-for="(img,index) in postFrom.recommendPost.images"
                class="popover-img cursor-pointer" :style="{
                    marginTop: index>=3?'16px':''
                  }" :src="fixImageUrl(img)" @click="$previewImage(postFrom.recommendPost.images,index)"
                fit="cover" :key="index">
              </el-image>
            </div>
            <!-- popover视频 -->
            <div class="popover-video" v-if="postFrom.recommendPost.video.thumbnail" :style="{
                  marginTop: postFrom.recommendPost.content?'13px':''
                }">
              <el-image class="video-thumbnail" :src="fixImageUrl(postFrom.recommendPost.video.thumbnail)"
                fit="cover">
              </el-image>
              <div class="video-mask cursor-pointer" @click="handlePlay(postFrom.recommendPost.video)">
                <img class="video-play" src="@/base/assets/images/media/video-play@2x.png" />
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="关联内容：" prop="">
          </el-form-item> -->
<!--      <el-form-item label="推荐：" prop="is_recommend">-->
<!--        <el-radio v-model="postFrom.is_recommend" :label="1">推荐</el-radio>-->
<!--        <el-radio v-model="postFrom.is_recommend" :label="0">不推荐</el-radio>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="显示：" prop="is_show">-->
<!--        <el-radio v-model="postFrom.is_show" :label="1">显示</el-radio>-->
<!--        <el-radio v-model="postFrom.is_show" :label="0">不显示 </el-radio>-->
<!--      </el-form-item>-->
    </el-form>
    <RecommandPostSelector v-model="openRecommandPostSelector" :selected="postFrom.recommendPost"
      @changeSelect="postSelect" />
    <VideoDialog />
    <FixedActionBar :z-index="2">
      <el-button type="primary" size="medium" @click="formSubmit('postFrom')" :loading="saveLoading">保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { postDetail, savePost } from "../../api/post-admin/detail";
import SingleMediaWall from "../../../common/components/SingleMediaWall.vue";
import MediaWall from "@/modules/common/components/MediaWall.vue";
import { groupOptions } from "../../api/group/group-list";
import { topicOptions } from "../../api/topic-admin/list";
import FixedActionBar from "@/base/layout/FixedActionBar";
import Tinymce from "@/base/components/Editor/Tinymce";
import RecommandPostSelector from "../../components/postAdmin/RecommandPostSelector";
import VideoDialog from "@/base/components/Preview/VideoViewer";
export default {
  components: {
    SingleMediaWall,
    FixedActionBar,
    MediaWall,
    Tinymce,
    RecommandPostSelector,
    VideoDialog,
  },
  data() {
    return {
      formChanging: false,
      postFrom: {
        id: this.$route.params.id,
        type: 0,
        group_id: this.$route.params.group_id || "",
        topic_id: this.$route.params.topic_id || "",
        description: "",
        category_id: "",
        video: [],
        images: [],
        content: "",
        recommend_post_id: "", // 推荐的帖子ID
        recommendPost: "",
        is_recommend: 1,
        is_show: 1,
      },
      rules: {
        images: [
          {
            message: "请上传图片",
            validator: (rule, value, callback) => {
              if (value.length && value) {
                callback()
              } else {
                callback(new Error('请上传图片'))
              }
            },
            trigger: 'change'
          },
          {
            required: true,
            message: "请上传图片",
            trigger: "change",
          },
        ],
      },
      topicOptions: [],
      topicOptionsloading: false,
      groupOptions: [],
      groupOptionsLoading: false,
      formLoading: false,
      saveLoading: false,
      openRecommandPostSelector: false,
    };
  },
  watch: {
    "postFrom.type"(val) {
      if (val === 3) {
        this.formChanging = true;
        this.rules = {
          content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        };
        this.$nextTick(() => {
          this.formChanging = false;
        });
      } else {
        this.formChanging = true;
        this.rules = {
          images: [
            {
              message: "请上传图片",
              validator: (rule, value, callback) => {
                if (value.length && value) {
                  callback()
                } else {
                  callback(new Error('请上传图片'))
                }
              },
              trigger: 'change'
            },
            {
              required: true,
              message: "请上传图片",
              trigger: "change",
            },
          ],
          video: [
            {
              required: true,
              validator: this.videoValidator,
              trigger: "change",
            },
          ],
          recommendPost: [
            {
              required: true,
              validator: this.postValidator,
              trigger: "change",
            },
          ],
        };
        this.$nextTick(() => {
          this.formChanging = false;
        });
      }

      this.postFrom.video = [];
      this.postFrom.images = [];
    },
    "postFrom.video": {
      deep: true,
      handler(val) {
        this.$refs.postFrom.validateField("video");
      },
    },
    'postFrom.images.length'(val) {
      this.$refs.postFrom.validateField("images");
    },
    "postFrom.recommendPost": {
      deep: true,
      handler(val) {
        this.$refs.postFrom.validateField("recommendPost");
      },
    },
  },
  computed: {
    cancelRouteName() {
      if (this.$route.params.group_id && this.$route.params.topic_id) {
        return this.$route.params.id == 0
          ? "groupTopicDetail"
          : "groupTopicPostDetail";
      } else if (this.$route.params.group_id && !this.$route.params.topic_id) {
        return this.$route.params.id == 0 ? "groupPostList" : "groupPostDetail";
      } else if (!this.$route.params.group_id && this.$route.params.topic_id) {
        return this.$route.params.id == 0 ? "topicDetail" : "topicPostDetail";
      } else {
        return this.$route.params.id == 0 ? "CirclePostList" : "postDetail";
      }
    },
  },
  methods: {
    // 获取帖子详情
    getPostDetail() {
      this.formLoading = true;
      postDetail({ id: this.postFrom.id })
        .then((res) => {
          this.postFrom = res.data;
          this.formLoading = false;
        })
        .catch((err) => {
          this.formLoading = false;
        });
    },
    // 验证视频上传
    videoValidator(rule, value, callback) {
      if (JSON.stringify(value) === "{}") {
        callback(new Error("请上传视频"));
      } else {
        callback();
      }
    },
    postValidator(rule, value, callback) {
      if (value === "") {
        callback(new Error("请选择帖子"));
      } else {
        callback();
      }
    },
    // 选择推荐帖子
    postSelect(val) {
      this.postFrom.recommendPost = val;
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit("media/setIsOpenMediaVideoDialog", true);
      this.$store.commit("media/setDialogDetail", data);
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.postFrom };
          this.saveLoading = true;
          data.recommend_post_id = data.recommendPost
            ? data.recommendPost.id
            : 0;
          savePost(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.cancelConfig();
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center", behavior: "smooth" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    // 取消
    cancelConfig() {
      if (this.$route.params.id == 0) {
        this.$router.push({
          name: this.cancelRouteName,
          params: {
            id: this.$route.params.group_id,
            topic_id: this.$route.params.topic_id,
          },
        });
      } else {
        this.$router.push({
          name: this.cancelRouteName,
          params: {
            id: this.$route.params.id,
            group_id: this.$route.params.group_id,
            topic_id: this.$route.params.topic_id,
          },
        });
      }
    },
  },
  created() {
    this.groupOptionsloading = true;
    this.topicOptionsloading = true;
    if (this.$route.params.id != 0) {
      this.getPostDetail();
    }
    groupOptions()
      .then((res) => {
        this.groupOptions = res.data;
        this.groupOptionsloading = false;
      })
      .catch((err) => {
        this.groupOptionsloading = false;
      });
    topicOptions({ group_id: 0 })
      .then((res) => {
        this.topicOptions = res.data;
        this.topicOptionsloading = false;
      })
      .catch((err) => {
        this.topicOptionsloading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.group-content {
  min-height: 520px;
}
.post-box {
  margin-top: 20px;
  width: 500px;
  background-color: rgb(248, 248, 248);
  padding: 20px;
  border-radius: 4px;
}
.post-content {
  width: 374px;
  padding: 12px;
  background-color: #fff;
  border-radius: 4px;
  .popover-imgs {
    display: flex;
    flex-wrap: wrap;
    .popover-img {
      width: 90px;
      height: 90px;
      margin-right: 16px;
      border-radius: 4px;
    }
  }
  .content-video {
    position: relative;
    width: 105px;
    height: 60px;
    .video-thumbnail {
      width: 100%;
      height: 100%;
    }
    .video-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.4);

      .video-play {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }
  }
  .popover-video {
    position: relative;
    width: 347px;
    height: 191px;
    .video-thumbnail {
      width: 100%;
      height: 100%;
    }
    .video-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.4);

      .video-play {
        display: inline-block;
        width: 46px;
        height: 46px;
      }
    }
  }
}
</style>
